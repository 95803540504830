import React, { useState } from "react";
import "./Modal.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import { motion } from "framer-motion";

const Modal = ({ data, close }) => {
  const { images, address } = data;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Стан для поточного зображення

  const modalVariants = {
    open: {
      opacity: 1,
      transition: { staggerChildren: 0.5, delayChildren: 0.2 },
    },
    closed: { opacity: 0 },
  };

  const imageVariants = {
    open: { opacity: 1, y: "0vh" },
    closed: { opacity: 0, y: "-10vh" },
  };

  const modalInfoVariants = {
    open: { opacity: 1, transition: { staggerChildren: 0.2 } },
    closed: { opacity: 0 },
  };

  const modalRowVariants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "10%" },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlideIndex(index), // Оновлюємо індекс поточного слайду
  };

  return (
    <motion.div
      className="modal"
      variants={modalVariants}
      onClick={(e) => e.stopPropagation()}
    >
      <motion.div className="modal__image" variants={imageVariants}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="modal__slide">
              <img
                src={image.url}
                alt={`Зображення ${index + 1}`}
                className="modal__slide-image"
              />
            </div>
          ))}
        </Slider>
      </motion.div>
      <motion.div className="modal__info" variants={modalInfoVariants}>
        <motion.div className="modal__row" variants={modalRowVariants}>
          <span className="modal__price">{address}</span>
        </motion.div>
        <motion.div className="modal__row" variants={modalRowVariants}>
          <p className="modal__description">
            {images[currentSlideIndex].description} {/* Опис поточного зображення */}
          </p>
        </motion.div>
        <motion.button
          className="modal__close-wrapper"
          whileHover={{ scale: 1.2 }}
          onClick={close}
        >
          <IoCloseCircleOutline className="modal__close-icon" />
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default Modal;