import React from "react";
import { BiChevronDown } from "react-icons/bi";
import "./accordionCopy.scss";
import { motion } from "framer-motion";

const Accordion = ({ index, title, activeIndex, setActiveIndex, text1, text2, text3, text4, text5, text6, text7, text8, cursive1, cursive2, cursive3, cursive4, cursive5, last }) => {

    const navVariants = {
        hidden: {
            opacity: 0,
            y: -50,
            transition: {
                type: 'spring',
                stiffness: 300,
                damping: 240,
            },
        },
        show: {
            opacity: 1,
            y: 0,
            transition: {
                type: 'spring',
                stiffness: 200,
                delay: 0.1,
            },
        },
    };

    const handleAccordionClick = () => {
        // Відкриває або закриває елемент в залежності від того, чи це той самий елемент
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <motion.div
            key={index}
            variants={navVariants}
            initial="hidden"
            whileInView="show"
            className={`accordion ${activeIndex === index ? 'active' : ''}`}
        >
            <div className="accordion__btn" onClick={handleAccordionClick}>
                <>{title}</>
                <div className="accordion__icon">
                    <BiChevronDown className="bx bxs-chevron-down" />
                </div>
            </div>
            {activeIndex === index && (
                <ul className="accordion__content">
                    {text1 && <li className="accordion__content--li">{text1}</li>}
                    {cursive1 && <li className="accordion__content--cursive">{cursive1}</li>}
                    {cursive2 && <li className="accordion__content--cursive">{cursive2}</li>}
                    {cursive3 && <li className="accordion__content--cursive">{cursive3}</li>}
                    {text2 && <li className="accordion__content--li">{text2}</li>}
                    {text3 && <li className="accordion__content--li">{text3}</li>}
                    {text4 && <li className="accordion__content--li">{text4}</li>}
                    {cursive4 && <li className="accordion__content--cursive">{cursive4}</li>}
                    {cursive5 && <li className="accordion__content--cursive">{cursive5}</li>}
                    {text5 && <li className="accordion__content--li">{text5}</li>}
                    {text6 && <li className="accordion__content--li">{text6}</li>}
                    {text7 && <li className="accordion__content--li">{text7}</li>}
                    {text8 && <li className="accordion__content--li">{text8}</li>}
                    {last && <li className="accordion__content--last">{last}</li>}
                </ul>
            )}
        </motion.div>
    );
};

export default Accordion;
