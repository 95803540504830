export const properties = [
  {
    id: 1,
    imageUrl: "../img/Pokrivlya/KyivObl.jpg",
    address: "ПОКРІВЛЯ",
    images: [
      { url: "../img/Pokrivlya/9-elements/IMG_0772.jpg", description: "КМ «9 елементів»" },
      { url: "../img/Pokrivlya/9-elements/IMG_0774.jpg", description: "КМ «9 елементів»" },
      { url: "../img/Pokrivlya/Kruhlouniversitetska-street/IMG_1327.jpg", description: "вул. Круглоуніверситетська" },
      { url: "../img/Pokrivlya/Kruhlouniversitetska-street/IMG_1328.jpg", description: "вул. Круглоуніверситетська" },
      { url: "../img/Pokrivlya/Pluti/IMG_0767.jpg", description: "Плюти" },
      { url: "../img/Pokrivlya/Pluti/IMG_0769.jpg", description: "Плюти" },
      { url: "../img/Pokrivlya/Pluti/IMG_0785.jpg", description: "Плюти" },
      { url: "../img/Pokrivlya/Puscha-vodistya/IMG_1317.jpg", description: "Пуща водиця" },
      { url: "../img/Pokrivlya/Yakutska-street/IMG_1502.jpg", description: "вул. Якутська" },
      { url: "../img/Pokrivlya/Filatova.jpg", description: "вул. Філатова" },
      { url: "../img/Pokrivlya/Filatova1.jpg", description: "вул. Філатова" },
      { url: "../img/Pokrivlya/Filatova2.jpg", description: "вул. Філатова" },
      { url: "../img/Pokrivlya/KyivObl.jpg", description: "Київська обл." },
      { url: "../img/Pokrivlya/KyivObl1.jpg", description: "Київська обл." },
      { url: "../img/Pokrivlya/Matrusivka.jpg", description: "с. Матрусівка" },
      { url: "../img/Pokrivlya/Matrusivka1.jpg", description: "с. Матрусівка" },
      { url: "../img/Pokrivlya/Matrusivka2.jpg", description: "с. Матрусівка" },
      { url: "../img/Pokrivlya/MVSsenter.jpg", description: "центр МВС" },
      { url: "../img/Pokrivlya/MVSsenter1.jpg", description: "центр МВС" },
    ],
  },
  {
    id: 2,
    imageUrl: "../img/Blahoustriy/City/IMG_1500.jpg",
    address: "БЛАГОУСТРІЙ",
    images: [
      { url: "../img/Blahoustriy/City/IMG_1500.jpg", description: "Місто" },
      { url: "../img/Blahoustriy/City/IMG_1505.jpg", description: "Місто" },
      { url: "../img/Blahoustriy/Emotsiya/IMG_0753.jpg", description: "ЖК Емоція" },
      { url: "../img/Blahoustriy/Emotsiya/IMG_0754.jpg", description: "ЖК Емоція" },
      { url: "../img/Blahoustriy/Emotsiya/IMG_0756.jpg", description: "ЖК Емоція" },
      { url: "../img/Blahoustriy/Kojumiatska-street/504.jpg", description: "вул. Кожумʼяцька" },
      { url: "../img/Blahoustriy/Kojumiatska-street/IMG_1504.jpg", description: "вул. Кожумʼяцька" },
      { url: "../img/Blahoustriy/Pohila-street/IMG_1324.jpg", description: "вул. Похила" },
      { url: "../img/Blahoustriy/hotiv.jpg", description: "с. Хотів" },
      { url: "../img/Blahoustriy/hotiv1.jpg", description: "с. Хотів" },
      { url: "../img/Blahoustriy/Kalinyvka.jpg", description: "вул. Калинівська" },
      { url: "../img/Blahoustriy/roshen.jpg", description: "Рошен м. Бориспіль" },
      { url: "../img/Blahoustriy/roshen1.jpg", description: "Рошен м. Бориспіль" },
      { url: "../img/Blahoustriy/roshen2.jpg", description: "Рошен м. Бориспіль" },
      { url: "../img/Blahoustriy/roshen3.jpg", description: "Рошен м. Бориспіль" },
      { url: "../img/Blahoustriy/sahaydak.jpg", description: "вул. Сагайдак" },
      { url: "../img/Blahoustriy/sahaydak1.jpg", description: "вул. Сагайдак" },
      { url: "../img/Blahoustriy/Yunit.jpg", description: "ЖК Юніт" },
      { url: "../img/Blahoustriy/Yunit1.jpg", description: "ЖК Юніт" },
    ],
  },
  {
    id: 3,
    imageUrl: "../img/hidroisolyatsiya/Hidroisolation-emotsiya/IMG_1497.jpg",
    address: "ГІДРОІЗОЛЯЦІЯ",
    images: [
      { url: "../img/hidroisolyatsiya/Hidroisolation-emotsiya/IMG_1497.jpg", description: "ЖК Емоція" },
      { url: "../img/hidroisolyatsiya/Hidroisolation-emotsiya/IMG_1496.jpg", description: "ЖК Емоція" },
      { url: "../img/hidroisolyatsiya/Hidroisolation-emotsiya/IMG_1495.jpg", description: "ЖК Емоція" },
      { url: "../img/hidroisolyatsiya/Hidroisolation-emotsiya/IMG_1494.jpg", description: "ЖК Емоція" },
      { url: "../img/hidroisolyatsiya/Hostomel.jpg", description: "стадіон Гостомель" },
    ],
  },
  {
    id: 4,
    imageUrl: "../img/Zahaliny/Horizont.jpg",
    address: "ЗАГАЛЬНОБУДІВЕЛЬНІ РОБОТИ",
    images: [
      { url: "../img/Zahaliny/Horizont.jpg", description: "БЦ Горизонт парк" },
      { url: "../img/Zahaliny/Horizont1.jpg", description: "БЦ Горизонт парк" },
    ],
  },
];